import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './home';
import {BrowserRouter, Route, Routes} from 'react-router-dom';


const routing = (

    <BrowserRouter>

        <Routes>
            <Route path='/' element={<Home/>}/>

        </Routes>

    </BrowserRouter>
)


ReactDOM.render([routing], document.getElementById('root'))


