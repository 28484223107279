import './App.scss';
// @ts-ignore
import logo from '../src/resources/img/logo.jpg'
import {LinkedinOutlined, MediumOutlined, MediumWorkmarkOutlined, TwitterOutlined} from "@ant-design/icons";

function Home() {
    return (
        <>
  {/*          <main className="mmetja-bg  align-self-center justify-content-center align-items-center h-screen">*/}

  {/*              <div className="header-left p-2">*/}
  {/*                  <img height={50} width={50} src={logo}></img>*/}
  {/*              </div>*/}


  {/*              <div className={'flex pt-20 pl-10 pr-10 justify-center items-center'}>*/}
  {/*                  <div*/}
  {/*                      className="max-w-screen-lg align-self-center justify-content-center align-items-center rounded align-self-center overflow-hidden shadow-lg">*/}
  {/*                      <div className="">*/}

  {/*                          <div*/}
  {/*                              className=" relative block overflow-hidden rounded-lg border-bottom border-gray-100 sm:p-6 lg:p-8">*/}
  {/*<span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"*/}
  {/*></span>*/}

  {/*                              <div className="p-10 sm:flex sm:justify-between sm:gap-4">*/}
  {/*                                  <div>*/}
  {/*                                      <h3 className=" text-4xl font-extrabold tracking-tight text-[#ace3dc] lg:text-3xl">*/}
  {/*                                          Mmetja P. Kekana*/}
  {/*                                      </h3>*/}

  {/*                                      <p className="mt-1  text-xs font-medium text-gray-200">Founder & Factotum at <a*/}
  {/*                                          target={'_blank'} href={'https://www.unit326.com/'}>Unit*/}
  {/*                                          326</a></p>*/}

  {/*                                      <p className={'pt-4 text-gray-100'}>Software Architect & Engineer passionate*/}
  {/*                                          about technology*/}
  {/*                                          <br/>and its potential to make the world a better place.*/}

  {/*                                      </p>*/}
  {/*                                  </div>*/}
  {/*                              </div>*/}


  {/*                              <dl className="mt-0 pl-10 pr-10 pb-10 flex gap-4 sm:gap-6">*/}
  {/*                                  <div className="flex flex-col-reverse">*/}

  {/*                                      <dd className="text-md text-gray-500">*/}

  {/*                                          <a target={'_blank'}*/}
  {/*                                             href={'https://www.linkedin.com/in/mmetja-kekana-95801a150/'}>*/}
  {/*                                              <LinkedinOutlined className={'p-2 text-gray-100'}/>*/}
  {/*                                          </a>*/}

  {/*                                          <a target={'_blank'}*/}
  {/*                                             href={'https://twitter.com/MmetjaKekana'}>*/}
  {/*                                              <TwitterOutlined className={'p-2 text-gray-100'}/>*/}
  {/*                                          </a>*/}

  {/*                                          <a target={'_blank'}*/}
  {/*                                             href={'https://medium.com/@mmetjakekana'}>*/}
  {/*                                              <MediumOutlined className={'p-2 text-gray-100'}/></a>*/}
  {/*                                      </dd>*/}
  {/*                                      <dt className="text-md font-medium text-[#ace3dc]">Connect</dt>*/}
  {/*                                  </div>*/}


  {/*                              </dl>*/}
  {/*                          </div>*/}

  {/*                      </div>*/}

  {/*                  </div>*/}
  {/*              </div>*/}
  {/*          </main>*/}






            <div className="container">
                <div className="drops">
                    <div className="drop drop-1"></div>
                    <div className="drop drop-2"></div>
                    <div className="drop drop-5"></div>
                </div>
                <form >
                        <div>
                            <h3 className=" text-4xl font-extrabold tracking-tight text-[#ace3dc] lg:text-3xl">
                                Mmetja P. Kekana
                            </h3>

                            <p className="mt-1 pb-0 mb-5 text-xs font-medium text-gray-200">Founder & Factotum at <a
                                target={'_blank'} href={'https://www.unit326.com/'}>Unit
                                326</a></p>

                            <p className={'pt-0 text-gray-100'}>Software Architect & Engineer passionate
                                about technology
                                <br/>and its potential to make the world a better place.

                            </p>
                        </div>
                    <dl className="mt-0 pl-10 pr-10 pb-10 flex gap-4 sm:gap-6">
                        <div className="flex flex-col-reverse">

                            <dd className="text-md text-gray-500">

                                <a target={'_blank'}
                                   href={'https://www.linkedin.com/in/mmetja-kekana-95801a150/'}>
                                    <LinkedinOutlined className={'p-2 text-gray-100'}/>
                                </a>

                                <a target={'_blank'}
                                   href={'https://twitter.com/MmetjaKekana'}>
                                    <TwitterOutlined className={'p-2 text-gray-100'}/>
                                </a>

                                <a target={'_blank'}
                                   href={'https://medium.com/@mmetjakekana'}>
                                    <MediumOutlined className={'p-2 text-gray-100'}/></a>
                            </dd>
                            <dt className="text-md font-medium text-[#ace3dc]">Connect</dt>
                        </div>


                    </dl>
                </form>

                <div className="drops">
                    <div className="drop drop-1"></div>
                    <div className="drop drop-2"></div>
                    <div className="drop drop-3"></div>
                    <div className="drop drop-4"></div>
                    <div className="drop drop-5"></div>
                </div>
            </div>
        </>
    );
}

export default Home;
